module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dan Parasky","short_name":"Dan Parasky","start_url":"/","background_color":"#485fc7","theme_color":"#485fc7","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e556c9ddbba123c2d9853ddbf1e38198"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"en","siteId":2239822,"cookiePolicyId":16702960,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#1255cc","acceptButtonCaptionColor":"#ffffff","customizeButtonColor":"#1255cc","customizeButtonCaptionColor":"#ffffff","rejectButtonDisplay":true,"rejectButtonColor":"#d8d8d8","rejectButtonCaptionColor":"#515151","position":"float-bottom-right","textColor":"white","backgroundColor":"#010101"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-193876640-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
